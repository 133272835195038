import { Coords } from 'google-map-react';
import React, { FC } from 'react';

import { PropertyPinIcon } from '../Icons';

import styles from './MapMarker.module.scss';

type MarkerProps = Coords & {
  children: React.ReactNode;
  className?: string;
  'data-testid'?: string;
};

const Marker: FC<MarkerProps> = ({ children, className, 'data-testid': dataTestId }) => (
  <div className={className} data-testid={dataTestId}>
    {children}
  </div>
);

type MapMarkerProps = Coords & {
  'data-testid'?: string;
};

export const MapMarker: FC<MapMarkerProps> = ({ lat, lng, 'data-testid': dataTestId }) => {
  return (
    <Marker lat={lat} lng={lng} data-testid={dataTestId}>
      <PropertyPinIcon className={styles.propertyPinIcon} />
    </Marker>
  );
};
