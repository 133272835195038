import compact from 'lodash/compact';

import {
  ListingListWithOfferEntryApiModel,
  PropertyDetailsResponseBody,
  PropertyStatus
} from '../api/_base/generated/data-contracts';

export const isAutoOfferVisible = (
  property: PropertyDetailsResponseBody | ListingListWithOfferEntryApiModel
) => {
  const { autoOfferIsEnabled, propertyStatus } = property;

  return autoOfferIsEnabled && propertyStatus === PropertyStatus.ACTIVE;
};

export const getCompactAddress = (property: Partial<PropertyDetailsResponseBody>) =>
  compact([
    property.streetAddress,
    property.city,
    compact([property.state, property.zipCode]).join(' ')
  ]).join(', ');

export const isPurchaseDirectProperty = (property: Partial<PropertyDetailsResponseBody>) =>
  property.isPurchaseDirectProperty &&
  !property.isStandardAuctionProperty &&
  !property.isExpressProperty;

export const isExpressProperty = (property: Partial<PropertyDetailsResponseBody>) =>
  !property.isPurchaseDirectProperty &&
  property.isStandardAuctionProperty &&
  property.isExpressProperty;

export const isPurchaseDirectAndExpressProperty = (
  property: Partial<PropertyDetailsResponseBody>
) =>
  property.isPurchaseDirectProperty &&
  !property.isStandardAuctionProperty &&
  property.isExpressProperty;

export const isPurchaseDirectAuctionProperty = (
  property: Partial<PropertyDetailsResponseBody | ListingListWithOfferEntryApiModel>
) => property.isPurchaseDirectProperty && property.isStandardAuctionProperty;

export const isPurchaseDirectAndExpressAuctionProperty = (
  property: Partial<PropertyDetailsResponseBody>
) =>
  property.isPurchaseDirectProperty &&
  property.isStandardAuctionProperty &&
  property.isExpressProperty;

export const isPurchaseDirectAndAuctionBiddingAllowed = (
  property: Partial<PropertyDetailsResponseBody>
) => isPurchaseDirectAuctionProperty(property) && property.hasPurchaseDirectOffer !== true;

export const isPurchaseDirectAndExpressAuctionPropertyHasPurchaseDirectOffer = (
  property: Partial<PropertyDetailsResponseBody>
) =>
  isPurchaseDirectAndExpressAuctionProperty(property) && property.hasPurchaseDirectOffer === true;
